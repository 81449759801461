<template>
	<div class="aboutUs">
		<headerNav :activeIndex="4" />
		<div class="banner" style="position:relative">
			<img src="../../assets/img/aboutUs.jpg" />
		</div>
		<div class="bannerText">
			<img src="../../assets/img/aboutUsText.png" />
		</div>
		<div class="aboutUsText">
			<div class="aboutUsTextImg">
				<img src="../../assets/img/aboutUsImg.png">
			</div>
			<div class="aboutUsText1">
				江苏钢链科技有限公司成立于2015年，伴随着中国钢铁产业及互联网技术的发展大趋势应运而生，是国内领先的钢材供应链集成服务平台。公司运营的"至刚网”
				平台，以工业用钢材(热卷、冷轧、中板、工业线材、型钢)、建筑钢材为主营品种，经营区域覆盖江、浙、沪等长三角各大城市，能为客户提供交易、物流、金融及信
				息化的一站式集成服务。
			</div>
			<div class="aboutUsText2">
				公司立足于用互联网模式变革传统钢铁贸易，打造最优效率钢铁流通新模式。经过近十年的运营，平台己能解决钢铁行业资源品类繁多、流通形式复杂、资产端融资难、
				资金端获客难及风险控制等问题。未来，"至刚网”将进一步夯实科技实力，下沉产业场景，为中国钢铁产业创新更大价值。
			</div>
		</div>
		<div class="aboutUsMap">
			<img src="../../assets/img/aboutUsImg1.png">
		</div>
		<div class="aboutUsUser">
			<div>
				<img src="../../assets/img/mapImg.png">
			</div>
			<div class="userText">
				<div class="userTextTitle">阳 勇</div>
				<div class="userTextSpan">
					<div class="userTextSpan1">联系方式</div>
					<div class="userTextSpan2">186-0731-3947</div>
				</div>
				<div class="userTextSpan">
					<div class="userTextSpan1">地址</div>
					<div class="userTextSpan2">江苏南京市玄武区红山路星河world 3栋611室</div>
				</div>
			</div>
		</div>

		<footerNav />
	</div>
</template>

<script>
	import footerNav from "../../components/footerNav.vue";
	import headerNav from "../../components/headerNav.vue";
	export default {
		name: "home",
		components: {
			footerNav,
			headerNav,
		},
		data() {
			return {

			};
		},
		mounted() {

		},
		methods: {


		},
	};
</script>

<style lang="scss" scoped>
	.aboutUs {
		min-width: 100vw;
		min-height: 100vh;
		text-align: center;
	}

	.banner {
		width: 100%;
		height: 575px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.bannerText {
		width: 100%;
		min-width: 1200px;
		text-align: center;
		margin: 0 auto;
		height: 575px;
		position: absolute;
		top: 280px;

		img {
			width: 494px;
			height: 289px;
			z-index: 99
		}
	}

	.aboutUsText {
		width: 1200px;
		height: 338px;
		background: #FFFFFF;
		box-shadow: 0px 0px 20px 0px rgba(215, 224, 255, 0.5);
		margin: 0 auto;
		position: relative;
		top: -62px;

		.aboutUsTextImg {
			margin: 0 auto;
			width: 104px;

			img {
				width: 100%;
				height: 100%;
				margin-top: 45px;
				margin-bottom: 20px;
			}
		}

		.aboutUsText1 {
			width: 1098px;
			height: 90px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 15px;
			color: #000012;
			line-height: 30px;
			text-align: left;
			font-style: normal;
			margin: 0px auto;
		}

		.aboutUsText2 {
			width: 1098px;
			height: 60px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 15px;
			color: #000012;
			line-height: 30px;
			text-align: left;
			font-style: normal;
			margin: 20px auto;
		}
	}

	.aboutUsMap {
		width: 1200px;
		margin: 20px auto;
		margin-bottom: 50px;

		img {
			width: 120px;
			height: 54px;
		}
	}

	.aboutUsUser {
		width: 1200px;
		margin: 0px auto;
		display: flex;
		padding-bottom: 200px;

		img {
			width: 647px;
			height: 447px;
		}

		.userText {
			width: 328px;
			height: 242px;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px 0px rgba(215, 224, 255, 0.5);
			margin-left: 118px;
			margin-top: 53px;
			padding: 30px 40px;

			.userTextTitle {
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: 18px;
				color: #202D37;
				line-height: 25px;
				text-align: left;
				font-style: normal;
				padding-bottom: 26px;
				border-bottom: 1px solid #E7E7E7;
			}

			.userTextSpan {
				margin-top: 30px;
				text-align: left;

				.userTextSpan1 {
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 15px;
					color: #999999;
					line-height: 21px;
					text-align: left;
					font-style: normal;
				}

				.userTextSpan2 {
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 15px;
					color: #202D37;
					line-height: 21px;
					text-align: left;
					font-style: normal;
					margin-top: 15px;
				}
			}
		}
	}
</style>